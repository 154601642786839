exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-gallery-paintings-index-jsx": () => import("./../../../src/pages/gallery/paintings/index.jsx" /* webpackChunkName: "component---src-pages-gallery-paintings-index-jsx" */),
  "component---src-pages-gallery-sketches-index-jsx": () => import("./../../../src/pages/gallery/sketches/index.jsx" /* webpackChunkName: "component---src-pages-gallery-sketches-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-paintings-shopify-product-product-type-index-jsx": () => import("./../../../src/pages/paintings/{ShopifyProduct.productType}/index.jsx" /* webpackChunkName: "component---src-pages-paintings-shopify-product-product-type-index-jsx" */),
  "component---src-pages-paintings-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/paintings/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-paintings-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-terms-of-sale-index-jsx": () => import("./../../../src/pages/terms-of-sale/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-sale-index-jsx" */)
}

